<template>
  <div class="order">
    <div class="order-head">
      <div class="order-head-r">
        <div class="order-head-r-time">
          <el-date-picker
            v-model="datas.time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '00:00:00']"
            value-format="yyyy-MM-dd"
            @change="clearDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="order-head-r-time">
          <span>订单类型：</span>
          <el-select v-model="datas.bill_type" filterable placeholder="请选择">
            <el-option
              v-for="item in tab"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="order-head-r-time">
          <span>员工姓名：</span>
          <el-select v-model="datas.staff_id" filterable placeholder="请选择">
            <el-option
              v-for="item in workData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="order-head-r-btn">
          <el-button type="primary" @click="search">搜索</el-button>
        </div>
      </div>
      <div class="order-head-l"></div>
    </div>

    <!-- 水单审核 -->
    <div class="order-cont">
      <el-table
        :data="tabData"
        :border="$table('border')"
        :stripe="$table('stripe')"
        key="2"
        :header-cell-style="{ background: $table('bgcolor') }"
      >
        <el-table-column prop="id" label="序号">
          <template slot-scope="scope">
            <span v-if="datas.page < 2">
              {{ scope.$index + 1 }}
            </span>
            <span v-else>
              {{ scope.$index + 1 + datas.page * 15 - 15 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row && scope.row.type ? getOrderType(scope.row.type) : '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="member" label="员工">
          <template slot-scope="scope">
            <span v-if="!scope.row.staffs.length">--</span>
            <span v-if="scope.row.staffs.length == 1">{{
              scope.row.staffs[0].staff_info
                ? scope.row.staffs[0].staff_info.name
                : "--"
            }}</span>

            <div v-if="scope.row.staffs.length > 1">
              <span v-if="0">{{ scope }}</span>
              <el-dropdown>
                <div class="el-dropdown-link">
                  {{
                    scope.row.staffs[0].staff_info
                      ? scope.row.staffs[0].staff_info.name
                      : "--"
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in scope.row.staffs"
                    :key="index"
                    >{{
                      item.staff_info ? item.staff_info.name : "--"
                    }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payments" label="主图">
          <template slot-scope="scope">
            <div class="order-infos-cont-item-img order-infos-cont-item-imgs">
              <img
                v-if="scope.row.goods_info.picture"
                :src="scope.row.goods_info.picture"
                :alt="scope.row.goods_info.name"
              />
              <img
                v-else
                src="../../assets/none.png"
                :alt="scope.row.goods_info.name"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bills" label="项目名称" width="160px">
          <template slot-scope="scope">
            <div class="order-infos-cont-item-img order-infos-cont-item-imgs">
              <el-popover
                placement="top-start"
                title="项目名称："
                width="160"
                trigger="hover"
                :content="
                  scope.row.goods_info.name ? scope.row.goods_info.name : '--'
                "
              >
                <!-- <el-button slot="reference">hover 激活</el-button> -->
                <!-- <i class="el-icon-s-comment" slot="reference"></i> -->
                <p slot="reference">
                  {{
                    scope.row.goods_info.name ? scope.row.goods_info.name : "--"
                  }}
                </p>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="order.sale_amount" label="订单价格">
        </el-table-column>
        <el-table-column prop="order.order_time" label="订单时间">
        </el-table-column>
        <el-table-column prop="order.remark" label="备注">
          <template slot-scope="scope">
            <div v-if="scope.row.order.remark">
              <el-popover
                placement="top-start"
                title="订单备注"
                width="160"
                trigger="hover"
                :content="scope.row.order.remark"
              >
                <!-- <el-button slot="reference">hover 激活</el-button> -->
                <i class="el-icon-s-comment" slot="reference"></i>
              </el-popover>
            </div>
            <span v-else style="color:#ccc;">无备注...</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="提成(手工费)">
          <template slot-scope="scope">
            <span v-if="!scope.row.staffs.length">--</span>
            <span v-if="scope.row.staffs.length == 1"
              >￥{{ scope.row.staffs[0]?scope.row.staffs[0].amount : "0.00" }}</span
            >

            <div v-if="scope.row.staffs.length > 1">
              <span v-if="0">{{ scope }}</span>
              <el-dropdown>
                <div class="el-dropdown-link">
                  ￥{{ scope.row.staffs[0]?scope.row.staffs[0].amount : "0.00"
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in scope.row.staffs"
                    :key="index"
                    >￥{{ item?item.amount : "0.00" }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="btn-box">
              <el-button size="mini" @click="show_order(scope.row)"
                >详情</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div>
        <el-pagination
          v-if="$pageStatus(datas.total)[0]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="datas.page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="$pageStatus(datas.total)[1]"
          layout="total,  prev, pager, next, jumper"
          :total="datas.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 水单审核/详情 -->

    <el-dialog
      :title="form1.type != 1 ? '订单详情' : ''"
      :visible.sync="dialogVisible"
      :append-to-body="dialogVisible"
      width="1200px"
    >
      <div class="order-infos" v-if="form1.id">
        <div class="order-infos-cont" v-if="form1.type != 1">
          <div class="order-infos-cont-item">
            <el-table
              :data="[form1.goods_info]"
              :border="$table('border')"
              key="7"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column label="名称">
                <template slot-scope="scope">
                  <!-- {{scope.row.goods_info}} -->
                  <div
                    class="order-infos-cont-item-img"
                    v-if="scope.row && scope.row.type && scope.row.type != 1"
                  >
                    <img
                      v-if="scope.row.picture"
                      :src="scope.row.picture"
                      :alt="scope.row.name"
                    />
                    <img
                      v-else
                      src="../../assets/none.png"
                      :alt="scope.row.name"
                    />
                    {{ scope.row.name ? scope.row.name : "--" }}
                  </div>
                  <span v-else>充值</span>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量">
                <template>x{{ form1.num || 0 }}</template>
              </el-table-column>
              <!-- <el-table-column prop="price" label="单价"></el-table-column> -->
              <el-table-column label="单价">
                <template slot-scope="scope">
                  {{scope.row && scope.row.price ? scope.row.price : '--'}}
                </template>
              </el-table-column>
              <el-table-column label="总价">
                <template slot-scope="scope">
                  {{scope.row && scope.row.price ? (form1.num * scope.row.price).toFixed(2) : '--'}}
                </template>
              </el-table-column>
              <!-- <el-table-column prop="staffs" label="经手人/提成">
                <template slot-scope="scope">
                  <span v-if="!form1.staffs.length">--</span>
                  <span v-if="form1.staffs.length == 1"
                    >{{ form1.staffs[0].staff_info.name }}/￥{{
                      form1.staffs[0].amount
                    }}</span
                  >

                  <div v-if="form1.staffs.length > 1">
                    <span v-if="0">{{ scope }}</span>
                    <el-dropdown>
                      <div class="el-dropdown-link">
                        {{ form1.staffs[0].staff_info.name }}/￥{{
                          form1.staffs[0].amount
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in form1.staffs"
                          :key="index"
                          >{{ item.staff_info.name }}/￥{{
                            item.amount
                          }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>

        <div class="order-infos-title">
          详情信息
        </div>
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span>订单编号：</span>
              <p>{{ form1.order.code }}</p>
            </div>
            <!-- <div class="order-infos-cont-item-list">
              <span>服务门店：</span>
              <p>{{ form1.shop ? form1.shop.name : "--" }}</p>
            </div> -->
            <div class="order-infos-cont-item-list">
              <span>订单时间：</span>
              <p>{{ form1.order.order_time }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>顾客信息：</span>
              <p>
                {{
                  form1.order.member
                    ? form1.order.member.name +
                      " (" +
                      form1.order.member.phone +
                      ")"
                    : "散客"
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>备注信息：</span>
              <p :title="form1.order.remark">
                {{ form1.order.remark || "无备注..." }}
              </p>
            </div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span>订单类型：</span>
              <p>
                {{ form1.type ? getOrderType(form1.type) : '--' }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>支付方式：</span>
              <p>
                {{
                  form1.payments.length
                    ? getPayType(form1.payments[0].pay_type)
                    : "其他"
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>订单金额：</span>
              <p>￥{{ form1.order.order_amount }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>实收金额：</span>
              <p>￥{{ form1.order.sale_amount }}</p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button @click="dialogVisible = false">确 认</el-button>
      </span>
    </el-dialog>

    <!-- 水单审核/详情 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tab: [
        { id: 0, name: "全部", data: [] },
        { id: 1, name: "充值", data: [] },
        { id: 2, name: "购卡", data: [] },
        { id: 3, name: "充次", data: [] },
        { id: 4, name: "商品", data: [] },
        { id: 5, name: "项目", data: [] },
        { id: 6, name: "收入", data: [] },
        { id: 7, name: "支出", data: [] },
      ],
      options: [
        {
          value: 1,
          label: "现金",
        },
        {
          value: 2,
          label: "微信",
        },
        {
          value: 3,
          label: "支付宝",
        },
        {
          value: 4,
          label: "银行卡",
        },
        {
          value: 5,
          label: "储值卡(余额)",
        },
        {
          value: 7,
          label: "核销",
        },
      ],
      workData: [{ id: 0, name: "全部" }], //员工数据
      tabData: [],
      form1: {},
      dialogVisible: false,
      datas: {
        page: 1,
        total: 1,
        staff_id: 0,
        bill_type: 0,
        date1: "",
        date2: "",
        time: [],
      },
    };
  },
  methods: {
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.datas.time = [];
        this.datas.date1 = "";
        this.datas.date2 = "";
        this.getList();
      }
      this.datas.date1 = this.datas.time[0];
      this.datas.date2 = this.datas.time[1];
    },
    /* 获取支付方式 */
    getPayType(obj) {
      for (let i = 0; i < this.options.length; i++) {
        if (obj - 0 == this.options[i].value - 0) {
          return this.options[i].label;
        }
      }
    },

    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.datas.page = val;

      //   this.tabData[this.tabDataIndex].page = val;
      //   this.datas.page = this.tabData[this.tabDataIndex].page;
      this.getList();
    },
    handleCurrentChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.datas.page = val;
      //   this.tabData[this.tabDataIndex].page = val;
      //   this.datas.page = this.tabData[this.tabDataIndex].page;
      this.getList();
    },
    search() {
      //   if (!this.datas.bill_type && !this.datas.staff_id) {
      //       this.$message.error('');
      //     return false;
      //   }
      this.datas.page = 1;
      this.getList();
    },
    getList() {
      let d = JSON.parse(JSON.stringify(this.datas));
      d.bill_type = d.bill_type ? d.bill_type : null;
      d.staff_id = d.staff_id ? d.staff_id : null;
      this.$axios({
        href: "/api/app/meiye/finance/bill_store",
        data: d,
      }).then((res) => {
        console.log(res);
        this.tabData = res.data.data.items;
        this.datas.total = res.data.data.total;
      });
    },

    handleEdit(obj) {
      this.type = 1;
      let d = JSON.parse(JSON.stringify(obj));
      console.log(d);
      let t = d.expire - 0;
      d.day = parseInt(t / 60 / 24);
      d.time = parseInt((t - d.day * 24 * 60) / 60);
      d.minute = t - d.day * 24 * 60 - d.time * 60;

      d.goods_info = [d.goods_info];
      d.is_coutuan = d.is_coutuan - 0 ? true : false;
      d.order_type = d.order_type ? "1" : "0";
      d.status = d.status > 0 ? "1" : "-1";

      this.form = d;
      this.dialogVisible = true;
    },
    /* 显示审核订单 */
    show_order(obj) {
      /* console.log(obj);
      this.form1 = obj;
      this.dialogVisible = true; */
      console.log('show_order_pramter', obj);
      this.$axios({
        href: "/api/app/meiye/finance/staff_commi_detail",
        data: { bill_id: obj.id },
      }).then((res) => {
        console.log('show_order', res)
        if (res.data) {
          this.form1 = res.data;
          this.dialogVisible = true;
        }
      });
    },
    /* 确定审单 */
    success_order() {
      if (!this.form1.id) {
        return false;
      }
      this.$confirm("此操作将审核该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/finance/order_audit",
            data: { oid: this.form1.id },
          }).then(() => {
            this.$message({
              type: "success",
              message: "审核订单成功!",
            });
            this.getList();
            this.dialogVisible = false;
          });
        })
        .catch(() => {});
    },
    /* 获取员工 */
    getWork() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        console.log(res);
        this.workData = this.workData.concat(res.data);
      });
    },
    /* 获取类型 */
    getOrderType(obj) {
      let len = this.tab.length;
      let d = this.tab;
      for (let i = 0; i < len; i++) {
        if (obj - 0 == d[i].id - 0) {
          return d[i].name;
        }
      }
    },
  },

  created() {
    this.getList();
    this.getWork();
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  height: auto;
  &-head {
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    &-l {
      min-width: 10px;
      width: 50%;
      height: 100%;
      display: none;
      // background: red;
    }
    &-r {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 360px;
        height: 100%;
        span {
          display: inline-block;
          width: 120px;
        }
        // background: chartreuse;
      }
      &-time {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 240px;
        height: 100%;
        margin: 0 10px;
        span {
          display: inline-block;
          width: 120px;
        }
        // background: coral;
      }
      &-btn {
        width: 80px;
        height: 100%;
        // background: cornflowerblue;
      }
      // background: chartreuse;
    }
  }
  &-cont {
    width: 100%;
    padding: 10px;
    height: auto;
    box-sizing: border-box;
  }
  &-infos {
    width: 100%;
    height: auto;
    &-title {
      width: 100%;
      height: 60px;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: Bold;
      text-align: left;
      color: #000000;
      line-height: 60px;
    }
    &-cont {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      height: auto;
      display: flex;
      &-item {
        width: 100%;
        height: auto;
        &-img {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 5px;
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }
        &-imgs {
          // margin-right: 5px;
          width: 100%;
          height: 20px;
          margin-bottom: 2px;
          // background: red;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // border-radius: 2px;
          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            border-radius: 2px;
          }
          p {
            max-width: 80px;
            height: 20px;
            margin-right: 2px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          // span{

          // }
        }
        &-list {
          width: 90%;
          height: 25px;
          margin-bottom: 10px;
          display: flex;
          span {
            display: inline-block;
            width: 100px;
            height: 100%;
            line-height: 25px;
            text-align: left;
            color: #888;
            // background: chartreuse;
          }
          p {
            padding: 0;
            color: #000;
            margin: 0;
            width: 65%;
            height: 25px;
            line-height: 25px;
            // background: red;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>

<style moudle>
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.order .el-tabs__content {
  /* display: none; */
  background: red;
}

.order .el-tabs__nav-wrap::after {
  width: 0%;
}
.order-head-r-input .el-input__inner {
  border-radius: 20px;
}
.order .centters {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
